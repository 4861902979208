$table-width: 175px;
$table-height: 175px;

.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25vh;
}

.table {
    position: relative;
    display: flex;
    place-items: center;
    width: $table-width;
    height: $table-height;
    border-radius: 50%;
    border: solid 1px;
}

.hint-comment {
    font-size: x-small;
    font-weight: bold;
    text-align: center;
    padding-bottom: 10px;
}