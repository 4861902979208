@use "../../style_vars.scss" as *;

.status-panel {
    justify-self: center;
    width: 60%;
    height: fit-content;
    background-color: #BBB;
    border-radius: 1em;
    padding: 0.5em;
    margin-top: 1em;
}