.dining-room {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 50px;
    padding: 50px;
    width: 80vw;
    height: 80vh;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.split-couple-comment {
    position: fixed;
    width: 150px;
    left: -100px;
    background-color: #C77;
    padding: 10px;
}