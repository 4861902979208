@import './DiningTable.scss';

$seat-width: 0.35 * $table-width;
$seat-height: 0.35 * $table-height;
$seat-translation: 0.75 * $table-height;

.seat {
    position: absolute;
    width: $seat-width;
    height: $seat-height;
    top: calc(50% - #{$seat-height}/2);
    left: calc(50% - #{$seat-width}/2);
    background: #999;
    text-align: center;
    font-size: small;
    display: flex;
    justify-content: center;
    align-items: center;
}

@for $i from 1 through 8 {
    .seat:nth-child(#{$i}) {
        transform: rotate(#{45deg * ($i - 1)}) translate(#{$seat-translation}) rotate(-#{45deg * ($i - 1)});
    }
}